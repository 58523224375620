$nav-height: 3.25rem;

.changelog{
  height: 35rem;
  padding-top: $nav-height;
  position: relative;
  background-color: white;
}

.log{
  margin: 0.5rem;
  ul{
    margin: 1rem 2rem;
  }
}