$nav-height: 3.25rem;

.home{
  padding-top: $nav-height;
  position: relative;
  background-color: white;
  height: 35rem;
}

.header{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content-container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  .title-search{
    display: flex;
    flex-direction: column;
    align-items: center;
    .form-container{
      display: flex;
      .field-container{
        input{
          height: 100%;
          width: 15rem;
          padding-left: 0.5rem;
        }
      }
      button{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 2rem;
        height: 2rem;
      }
    }
    .results-section{
      position: absolute;
      margin-top: 2.5rem;
      background-color: white;
      ul{
        list-style: none;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .movie-output{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ul{
      margin-top: 1.5rem;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li{
        margin: 0.25rem;
        a{
          display: flex;
          flex-direction: column;
          width: 6.55rem;
          text-align: center;
          transition: transform 0.2s;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 8px 12px;
          text-decoration: none;
          color: black;
          background-color: white;
          img{
            height: 10rem;
            width: 100%;
          }
        }
        a:hover{
          transform: scale(1.2);
        }
      }
    }
  }
}

@media (max-width: 480px){
  .content-container{
    flex-direction: column;
  }
}